<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="公告标题">
                <a-input v-model="queryParam.noticeTitle" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="操作人员">-->
<!--                <a-input v-model="queryParam.createBy" placeholder="请输入" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="关联类型">
                <a-select placeholder="请选择关联类型" v-model="queryParam.relationType" style="width: 100%" allow-clear>
                  <a-select-option v-for="(item, index) in this.customDict.SysNoticeRelationTypeEnum" :value="item.type" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态">
                <a-select placeholder="请选择" v-model="queryParam.status" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type['sys_notice_status']" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="公告类型">
                <a-select placeholder="请选择公告类型" v-model="queryParam.noticeType" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type['sys_notice_type']" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a-button style="margin-left: 8px" @click="batchEnable(2)"><a-icon type="eye-invisible" />批量开启</a-button>-->
                <a-button style="margin-left: 8px" @click="batchClose(2)"><a-icon type="eye-invisible" />批量关闭</a-button>
                <a-button style="margin-left: 8px" @click="batchClose(1)"><a-icon type="eye-invisible" />全部关闭</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
<!--      <div class="table-operations">
        <a-button type="primary" @click="handleAdd()" v-hasPermi="['system:notice:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="handleUpdate(undefined, ids)" v-hasPermi="['system:notice:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:notice:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>-->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        :data-source="list"
        :rowKey="(record, index) => index + 1"
        :columns="columns"
        :customRow="columnClick"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered">
        <span slot="noticeType" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_type']" :value="record.noticeType" />
        </span>
        <span slot="relationType" slot-scope="text, record">
          <custom-dict-tag :options="customDict.SysNoticeRelationTypeEnum" :value="record.relationType" />
        </span>
        <span slot="status" slot-scope="text, record">
          <a-badge
            :status="record.status === '0' ? 'processing' : 'error'"
            :text="record.status === '0' ? '正常' : '关闭'"
          />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleUpdate(record, undefined)" v-hasPermi="['system:notice:edit']">
<!--            <a-icon type="edit" />修改-->
            <a-icon type="edi" />查看详情
          </a>
          <a-divider type="vertical" v-hasPermi="['system:notice:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['system:notice:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { listNotice, delNotice, batchCloseStatus } from '@/api/system/notice'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'
import CustomDictTag from '@/components/DictCustomTag/index.vue'

export default {
  name: 'Notice',
  components: {
    CustomDictTag
  },
  mixins: [tableMixin],
  dicts: ['sys_notice_status', 'sys_notice_type'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        noticeTitle: undefined,
        relationType: undefined,
        remark: undefined,
        createBy: undefined,
        status: undefined
      },
      columns: [
        {
          title: '公告编号',
          dataIndex: 'noticeId',
          width: 80,
          align: 'center'
        },
        {
          title: '公告标题',
          dataIndex: 'noticeTitle',
          ellipsis: true,
          align: 'left'
        },
        {
          title: '公告类型',
          dataIndex: 'noticeType',
          scopedSlots: { customRender: 'noticeType' },
          width: '10%',
          align: 'center'
        },
        {
          title: '关联类型',
          dataIndex: 'relationType',
          scopedSlots: { customRender: 'relationType' },
          width: '15%',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: '10%',
          align: 'center'
        },
        // {
        //   title: '操作人员',
        //   dataIndex: 'createBy',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 170,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询公告列表 */
    getList () {
      this.loading = true
      listNotice(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
          this.selectedRowKeys = []
          this.selectedRows = []
          this.ids = []
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        noticeTitle: undefined,
        relationType: undefined,
        remark: undefined,
        createBy: undefined,
        status: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.noticeId)
      this.verifyStatus()
    },
    verifyStatus() {
      this.single = this.selectedRowKeys.length !== 1
      this.multiple = !this.selectedRowKeys.length
    },
    columnClick(row, index) {
      return {
        on: {
          click: () => {
            if (this.selectedRowKeys.includes(index + 1)) {
              const keyIndex = this.selectedRowKeys.findIndex(i => i === index + 1)
              this.selectedRowKeys.splice(keyIndex, 1)
              this.selectedRows.splice(keyIndex, 1)
              this.ids.splice(keyIndex, 1)
              this.verifyStatus()
            } else {
              this.selectedRowKeys.push(index + 1)
              this.selectedRows.push(row)
              this.ids.push(row.noticeId)
              this.verifyStatus()
            }
          }
        }
      }
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const noticeIds = row.noticeId || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + noticeIds + '的数据',
        onOk () {
          return delNotice(noticeIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.$router.push({
        name: 'NoticeForm',
        params:
        {
          id: undefined,
          formTitle: '添加公告'
        }
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      const noticeId = row ? row.noticeId : ids
      this.$router.push({
        name: 'NoticeForm',
        params:
        {
          id: noticeId,
          // formTitle: '修改公告'
          formTitle: '公告详情'
        }
      })
    },
    batchClose (type) {
      console.log(this.ids)
      batchCloseStatus(
        type,
        this.queryParam.relationType ? this.queryParam.relationType : 0,
        this.ids.length > 0 ? this.ids : -1
      ).then(() => {
        this.$message.success(
          '关闭状态成功！',
          3
        )
        this.getList()
      })
    }
  }
}
</script>
